import axios from "axios";

export default axios.create({
  
  // baseURL: "http://183.91.71.214:4001",
  // baseURL: "http://192.168.200.82:1007",
  baseURL: "http://202.152.63.41:1007",
  //  baseURL: "http://apiprod.askrida.com",
  // baseURL: "http://apidev.askrida.com",
  headers: {
    "Content-type": "application/json",
  },
  timeout: 30 * 60000
});
