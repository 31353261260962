import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
// menu routing
const RoleManagement = Loadable(lazy(() => import('views/menu/user-management/FormRoleManagement')));
const RoleMenu = Loadable(lazy(() => import('views/menu/user-management/FormRoleMenu')));
const MenuManagement = Loadable(lazy(() => import('views/menu/user-management/FormMenuManagement')));
const UserManagement = Loadable(lazy(() => import('views/menu/user-management/FormUserManagement')));
const ChangePassword = Loadable(lazy(() => import('views/menu/user-management/FormChangePassword')));
const FormResetPassword = Loadable(lazy(() => import('views/menu/user-management/FormResetPassword')));
const FormCabangKantor = Loadable(lazy(() => import('views/menu/user-management/FormCabangKantor')));

// monitoirng data
const FormListData = Loadable(lazy(() => import('views/menu/Monitoring/FormListData')));
const FormRekapDataPolis = Loadable(lazy(() => import('views/menu/Monitoring/FormRekapDataPolis')));
const FormListDataNonCaptive = Loadable(lazy(() => import('views/menu/Monitoring/FormListDataNonCaptive')));

const FormRekon = Loadable(lazy(() => import('views/menu/Monitoring/FormRekon')));
const FormRekonNonCaptive = Loadable(lazy(() => import('views/menu/Monitoring/FormRekonNonCaptive')));
const FormListDataRekeningKoranMedan = Loadable(lazy(() => import('views/menu/Monitoring/FormListDataRekeningKoranMedan')));


const FireInsurance = Loadable(lazy(() => import('views/menu/insurance/fire-insurance')));

const FormInsurance = Loadable(lazy(() => import('views/menu/insurance/FormInsurance')));
const FormListDataPenutupan = Loadable(lazy(() => import('views/menu/insurance/FormListDataPenutupan')));

// FLAGING
const FormFlaging = Loadable(lazy(() => import('views/menu/flaging/FormFlaging')));


// ASURANSI
const FormKalkulatorPremi = Loadable(lazy(() => import('views/menu/asuransi/FormKalkulatorPremi')));
const FormPengajuanPolisKredit = Loadable(lazy(() => import('views/menu/asuransi/FormPengajuanPolisKredit')));


// FROMCBCiNSURANCE
const FormCbcInsurance = Loadable(lazy(() => import('views/menu/cbc/formCbcInsurance')));
const FormCbcInsuranceListData = Loadable(lazy(() => import('views/menu/cbc/formCbcInsuranceListData')));


const FormListDataPengajuanCBC = Loadable(lazy(() => import('views/menu/cbc/FormListDataPengajuanCBC')));
const FormListDataVerifikasiPersetujuanCBC = Loadable(lazy(() => import('views/menu/cbc/FormListDataVerifikasiPersetujuanCBC')));

// KLAIM BANK SUMUT MEDAN
const FormListDataClaim = Loadable(lazy(() => import('views/menu/klaim/FormListDataClaim')));
const FormListDataClaimStuju = Loadable(lazy(() => import('views/menu/klaim/FormListDataClaimStuju')));

// login
const Logins = Loadable(lazy(() => import('views/login/Login3')));

// ini untuk test JIWA
const FormListDataToJiwa = Loadable(lazy(() => import('views/menu/asuransi-jiwa/FormListDataToJiwa')));

// ===========================|| MAIN ROUTING ||=========================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/login',
            element: <Logins />
        },
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/utils/util-typography',
            element: <UtilsTypography />
        },
        {
            path: '/utils/util-color',
            element: <UtilsColor />
        },
        {
            path: '/utils/util-shadow',
            element: <UtilsShadow />
        },
        {
            path: '/icons/tabler-icons',
            element: <UtilsTablerIcons />
        },
        {
            path: '/icons/material-icons',
            element: <UtilsMaterialIcons />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        // mulai dari sini
        {
            path: '/role-management',
            element: <RoleManagement />
        },
        {
            path: '/role-menu',
            element: <RoleMenu />
        },
        {
            path: '/menu-management',
            element: <MenuManagement />
        },
        {
            path: '/user-management',
            element: <UserManagement />
        },
        {
            path: '/kantor-cabang',
            element: <FormCabangKantor />
        },
        {
            path: '/change-password',
            element: <ChangePassword />
        },
        {
            path: '/reset-password',
            element: <FormResetPassword />
        },
        // monitoirng data
        {
            path: '/data-transaksi',
            element: <FormListData />
        },

        {
            path: '/data-transaksi-nc',
            element: <FormListDataNonCaptive />
        },

        
        // rekap
        {
            path: '/data-rekap',
            element: <FormRekapDataPolis />
        },
        // monitoirng data
        {
            path: '/rekon-data',
            element: <FormRekon />
        },
        {
            path: '/rekon-data-penutupan-nc',
            element: <FormRekonNonCaptive />
        },
        // monitoirng data
        {
            path: '/rekeningkoran-medan',
            element: <FormListDataRekeningKoranMedan />
        },
        //  insurance
        {
            path: '/fire-insurance',
            element: <FireInsurance />
        },
        
      
       
        // insurance
        {
            path: '/FormInsurance',
            element: <FormInsurance />
        },

        {
            path: '/form-data-penutupan',
            element: <FormListDataPenutupan />
        },
// flaging
        {
            path: '/flaging',
            element: <FormFlaging />
        },
// CBC
        {
            path: '/pengajuan-cbc',
            element: <FormCbcInsurance />
        },

        {
            path: '/pengajuan-cbc-list-data',
            element: <FormCbcInsuranceListData />
        },


        {
            path: '/list-data-cbc',
            element: <FormListDataPengajuanCBC />
        },


        {
            path: '/verifikasi-persetujuan-cbc',
            element: <FormListDataVerifikasiPersetujuanCBC />
        },


        // KLAIM MEDAN BANK SUMUT

        
        {
            path: '/data-klaim',
            element: <FormListDataClaim />
        },

        {
            path: '/data-klaim-persetujuan',
            element: <FormListDataClaimStuju />
        },


        // ASURANSI

        {
            path: '/kalkulator-premi-kredit',
            element: <FormKalkulatorPremi />
        },
        {
            path: 'pengajuan-polis-kredit',
            element: <FormPengajuanPolisKredit />
        },
        // ASURANSI JIWA
        {
            path: '/data-kredit',
            element: <FormListDataToJiwa />
        },
    ]
};

export default MainRoutes;
